import * as React from 'react'
import PropTypes from 'prop-types'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Icon from '@/components/Icon'
import theme from '@/theme'

import {
  Container,
  CircularButton,
  TextContainer,
  Tags,
  Title,
  ShareButton,
  ShareText,
  Paragraphs,
  GreenText,
  Wysiwyg,
  ImageWrapper,
  TextWrapper,
  SectionOne,
  BackShareNav,
  BackButton,
  CircularBtnWrapper
} from './style'

const ArticleTextBlock = ({
  tags = '',
  title = '',
  greenText = '',
  greenTextUppercaseOne = '',
  greenTextUppercaseTwo = '',
  textOne = '',
  img = '',
  textHalfBlockOne = '',
  textHalfBlockTwo = '',
  hideShareButton = false,
  hideBackButton = false
}) => {
  return (
    <Container>
      <BackShareNav>
        {!hideBackButton &&
          <BackButton to='/journal'>
            <Icon
              color={ theme.colors.brown }
              name='arrow-left'
              size={ 32 }
            />
            Back
          </BackButton>}

        { !hideShareButton &&
        (
          <ShareButton className='small'>
            <Icon
              color={ theme.colors.brown }
              name='share'
            />
            <ShareText>
              Share
            </ShareText>
          </ShareButton>
        )}

      </BackShareNav>

      <SectionOne>
        {!hideBackButton &&
          <CircularBtnWrapper>
            <CircularButton
              id="textcercle"
              icon='arrow-left'
              label='Back'
              color='blue'
              nbLabels={ 11 }
              to='/journal'
            />
          </CircularBtnWrapper>}

        <TextContainer>

          <Tags>
            { tags }
          </Tags>

          <Title>
            { title }
          </Title>

          <Paragraphs>

            <GreenText>
              { greenText }
            </GreenText>

            <GreenText className='uppercase'>
              { greenTextUppercaseOne }
            </GreenText>

            <Wysiwyg className='noBreak'>
              { textOne }
            </Wysiwyg>

          </Paragraphs>

        </TextContainer>

        {!hideShareButton && <ShareButton>
          <Icon
            color={ theme.colors.green }
            name='share'
          />
          <ShareText>
            Share this article
          </ShareText>
                             </ShareButton>}

      </SectionOne>

      {img && <ImageWrapper img={ resolveAssetURL(img) } />}

      <TextContainer>

        <Paragraphs>

          <GreenText className='uppercase'>
            { greenTextUppercaseTwo }
          </GreenText>

          <TextWrapper>

            <Wysiwyg className='halfBlock'>
              { textHalfBlockOne }
            </Wysiwyg>

            <Wysiwyg className='halfBlock'>
              { textHalfBlockTwo }
            </Wysiwyg>

          </TextWrapper>

        </Paragraphs>

      </TextContainer>

    </Container>
  )
}

ArticleTextBlock.propTypes = {
  tags: PropTypes.string,
  title: PropTypes.string,
  greenText: PropTypes.string,
  greenTextUppercaseOne: PropTypes.string,
  greenTextUppercaseTwo: PropTypes.string,
  textOne: PropTypes.string,
  textHalfBlockOne: PropTypes.string,
  textHalfBlockTwo: PropTypes.string,
  img: PropTypes.string,
  hideShareButton: PropTypes.bool,
  hideBackButton: PropTypes.bool
}

export default ArticleTextBlock
