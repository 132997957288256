import styled from '@emotion/styled'
import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'
import CircularLabelButton from '@/components/CircularLabelButton'
import WysiwygBase from '@/components/Wysiwyg'

import { mediaMax, sectionSubtitle } from '@/styles/mixins'

export const Container = styled.div`
  position:relative;
  width: 80%;
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-bottom: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaMax.md} {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
  ${mediaMax.xs} {
    width: 90%;
  }
`

export const SectionOne = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${mediaMax.md} {
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
  }
`

export const CircularBtnWrapper = styled.div`
  width: 15%;
  margin-top: 22rem;
  display: flex;
  justify-content: center;
  ${mediaMax.md} {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
  }
`

export const CircularButton = styled(CircularLabelButton)`
  width: min-content;
  ${mediaMax.lg} {
    transform: scale(1);
  }
`

export const ShareButton = styled.button`
  ${sectionSubtitle()}
  background: none;
  border: none;
  outline: none;
  height: min-content;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content:center;
  gap: 1.2rem;
  margin-top: ${({ theme }) => theme.spacing.medium};
  cursor: pointer;
  ${mediaMax.lg} {
    flex-direction:row-reverse;
  }
  ${mediaMax.md} {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  &.small {
    margin-top: 0;
    ${mediaMax.md} {
      visibility: visible;
      width: auto;
      height: auto;
    }
  }
`

export const ShareText = styled.p`
  ${sectionSubtitle()}
  ${mediaMax.md} {
    color: ${({ theme }) => theme.colors.brown};
  }
  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.paragraph.xs};
  }
`

export const BackShareNav = styled.div`
  display: flex;
  justify-content: space-between;
  visibility: hidden;
  width: 0;
  height: 0;
  ${mediaMax.md} {
    visibility: visible;
    width: 90%;
    height: auto;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`

export const BackButton = styled(LocalizedGatsbyLink)`
  ${sectionSubtitle()}
  display: flex;
  align-items:center;
  gap: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  ${mediaMax.md} {
    color: ${({ theme }) => theme.colors.brown};
  }
  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.paragraph.xs};
  }
`

export const TextContainer = styled.div`
  width: 70%;
  ${mediaMax.md} {
    width: 75%;
  }
  ${mediaMax.xs} {
    width: 90%;
  }
`

export const Tags = styled.div`
    ${sectionSubtitle()}
    margin-bottom:${({ theme }) => theme.spacing.xsmall};
`

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.title.lg};
  font-family: ${({ theme }) => theme.fonts.serif};
  color: ${({ theme }) => theme.colors.brown};
  font-weight: bold;
  margin-bottom:${({ theme }) => theme.spacing.small};
  ${mediaMax.xs} {
    word-wrap: break-word;
  }
`

export const Paragraphs = styled.div`
    margin-left:${({ theme }) => theme.spacing.xsmall};
    ${mediaMax.xs} {
        margin-left:0;
    }
`

export const ImageWrapper = styled.div`
  width: 60vw;
  height: 65vh;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: bottom;
  margin-top: 6rem;
  margin-bottom: 6rem;
  ${mediaMax.md} {
    margin-top: 0;
    margin-bottom: 6rem;
    width: 100%;
    height: 40vh;
  }
  ${mediaMax.xs} {
    width: 111%;
    height: 40vh;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .halfBlock{
      width: 45%;
  }
  ${mediaMax.md} {
    flex-direction:column;
    justify-content: flex-start;
    .halfBlock{
      width: 100%;
      margin-bottom: ${({ theme }) => theme.spacing.xsmall};
    }
  }
`

export const GreenText = styled(WysiwygBase)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.green};
  line-height: 1.875rem;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  &.uppercase{
    line-height: 1.625rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    ${mediaMax.xs} {
      line-height: 3.0rem;
    }
    ${mediaMax.md} {
      margin-bottom: ${({ theme }) => theme.spacing.small};
    }
  }
`

export const Wysiwyg = styled(WysiwygBase)`
  p {
    color: ${({ theme }) => theme.colors.grey};
    ${mediaMax.xs} {
      width: 100%;
    }
  }
`
